<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!candidate"
        color="secondary"
        elevation="0"
        small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-else
        color="secondary"
        elevation="0"
        small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ this.$t('candidate.select_or_create.dialog_title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <PersonalData :candidate="candidate" @saved="saved" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import PersonalData from '@/components/candidates/PersonalData.vue'

export default {
  name: 'CandidateDialog',
  components: { PersonalData },
  props: {
    candidate: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    ...mapActions('candidates', ['getCandidates', 'getCandidate']),
    saved(candidateId) {
      this.getCandidates({"page": "-1"}).then(() => {
        if (candidateId) this.getCandidate(candidateId)
        this.dialog = false
      })
    }
  }
}
</script>

<style scoped></style>
