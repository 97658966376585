import i18n from '@/i18n'

export const weekdays = [
  {
    code: 1,
    name: i18n.t('global.weekdays.monday')
  },
  {
    code: 2,
    name: i18n.t('global.weekdays.tuesday')
  },
  {
    code: 3,
    name: i18n.t('global.weekdays.wednesday')
  },
  {
    code: 4,
    name: i18n.t('global.weekdays.thursday')
  },
  {
    code: 5,
    name: i18n.t('global.weekdays.friday')
  },
  {
    code: 6,
    name: i18n.t('global.weekdays.saturday')
  },
  {
    code: 7,
    name: i18n.t('global.weekdays.sunday')
  }
]

export default weekdays
