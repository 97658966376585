<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('candidate.select_or_create.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <!--
    Do not condition display with v-if="!dataIsLoading" !
    Sub components need to be mounted to emit callback after API calls.
    -->
    <v-row align="center">
      <v-col>
        <span v-if="jobApplication !== null && jobApplication.id !== null">
          {{ candidateFullNameAndBirthdate(candidate) }}
        </span>
        <v-autocomplete
          v-else
          v-model="selectedCandidate"
          :items="Object.values(candidates.items)"
          :item-text="candidateFullNameAndBirthdate"
          item-value="id"
          :label="$t('candidate.select_or_create.select_candidate')"
          @change="updateSelectedCandidate"
        ></v-autocomplete>
      </v-col>
      <v-col cols="1">
        <CandidateDialog :candidate="candidate"></CandidateDialog>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { formatDate } from '@/helpers/dates'
import CandidateDialog from '@/components/candidates/CandidateDialog.vue'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  name: 'SelectOrCreateCandidate',
  components: { ComponentTitleWithDataLoader, CandidateDialog },
  data() {
    return {
      selectedCandidate: null
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('candidates', ['candidates', 'candidate']),
    ...mapState('jobApplications', ['jobApplication'])
  },
  watch: {
    candidate: {
      handler(candidate) {
        this.selectedCandidate = candidate.id
      }
    }
  },
  mounted() {
    this.getCandidates({sortBy: ['last_name'], sortDesc: [false], page: '-1'})
  },
  methods: {
    ...mapActions('candidates', ['getCandidates', 'getCandidate']),
    candidateFullNameAndBirthdate(candidate) {
      if (candidate === null) return ''
      return `${candidate.first_name} ${candidate.last_name} (${formatDate(
        candidate.birthdate
      )})`
    },
    updateSelectedCandidate() {
      if (this.selectedCandidate !== null)
        this.getCandidate(this.selectedCandidate)
    }
  }
}
</script>

<style scoped></style>
