<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :return-value.sync="formattedDate"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :id="id"
        :value="formattedDate"
        :label="label"
        clearable
        prepend-icon="mdi-calendar"
        :rules="dateRules"
        :class="required ? 'required': ''"
        :data-cy="cytext"
        v-bind="attrs"
        v-on="on"
        @change="updateFromText"
        @click:clear="updateFromText(null)"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="date"
      no-title
      scrollable
      locale="fr-CH"
      :min="endDateMin"
      :first-day-of-week="1"
      :data-cy="cydate"
      @change="updateFromDatePicker"
    />
  </v-menu>
</template>

<script>
import moment from 'moment/moment'
import { formatDate, parseDateFromManualEntry, DATE_MANUAL_ENTRY_FORMAT, DATE_PICKER_FORMAT } from '@/helpers/dates'

export default {
  props: {
    value: { type: String, required: false, default: "" },
    label: { type: String, required: true },
    id: { type: String, required: false, default: ""},
    rules: {type: Array, required: false, default: () => []},
    min: { type: String, required: false, default: null },
    required: { type: Boolean, required: false, default: false },
    cytext: { type: String, required: false, default: '' },
    cydate: { type: String, required: false, default: '' },
  },
  data() {
    return {
      menu: false,
      date: this.value,
      dateRules: [
        ...[v => !v || moment(v, DATE_MANUAL_ENTRY_FORMAT, true).isValid() || this.$t('global.validation.errors.date_format')],
        ...this.rules,
        ...this.required ? [v => !!v,] : [],
        ...this.min ? [v =>  (!this.required || moment(this.min) < moment(v, DATE_MANUAL_ENTRY_FORMAT, true)) || this.$t('global.validation.errors.date_min', {min: formatDate(this.min)})] : []
      ]
    }
  },
  computed: {
    endDateMin() {
      if (!this.allowPast) {
        return moment(this.min).format(DATE_PICKER_FORMAT)
      }
      return null
    },
    formattedDate: {
      get() {
        return formatDate(this.date)
      },
      set() {

      }
    }
  },
  watch: {
  },
  methods: {
    updateFromText(data) {
      this.menu = false
      this.date = parseDateFromManualEntry(data)
      this.$emit('update', this.date)
    },
    updateFromDatePicker(data) {
      this.menu = false
      this.date = data
      this.$emit('update', this.date)
    }
  }
}
</script>
