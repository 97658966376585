<template>
  <div>
    <PageTitle
      icon="mdi-account"
      :title="
        jobApplicationId
          ? `${$t('global.application')} ${jobApplicationId} - ${candidateFullName}`
          : `${$t('jobApplication_details.title')} - ${candidateFullName}`
      "
      back-button
    ></PageTitle>
    <v-row class="mb-0">
      <v-col cols="12" sm="4" class="pt-0">
        <JobEntityStatus
          entity-type="application"
          :entity="jobApplication"
          :is-selection-valid="isSelectionValid"
        />
        <SelectOrCreateCandidate class="mt-8"></SelectOrCreateCandidate>
      </v-col>
      <v-col cols="12" sm="8" class="pt-4 pt-sm-0">
        <JobApplication :job-application="jobApplication" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import JobApplication from '@/components/jobApplications/JobApplication.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import SelectOrCreateCandidate from '@/components/candidates/SelectOrCreateCandidate.vue'
import JobEntityStatus from "@/components/common/JobEntityStatus.vue";

export default {
  components: {
    JobEntityStatus,
    SelectOrCreateCandidate,
    PageTitle,
    JobApplication
  },
  computed: {
    ...mapState('jobApplications', ['jobApplication']),
    ...mapState('candidates', ['candidate']),
    jobApplicationId() {
      return this.$route.params.id
    },
    candidateFullName() {
      let candidate;
      if (this.jobApplication) {
        candidate = this.jobApplication?.candidate
      } else {
        candidate = (this.candidate) ? this.candidate : null
      }

      return candidate
        ? `${candidate.first_name} ${candidate.last_name}`
        : '...'
    },
    isSelectionValid() {
      const selections = this.jobApplication?.versions[0].jobapplicationactivityselection_set || []
      return (
        selections.length > 0 &&
        !selections.find((obj) => obj.activity.implicit_activity === null)
      )
    },
  },
  mounted() {
    this.setManualDataIsLoading(true)
    if (this.jobApplicationId) {
      this.getJobApplication(this.jobApplicationId).then(response => {
        this.getCandidate(response.candidate.id).then(() => {
          this.setManualDataIsLoading(false)
        })
      })
    } else {
      this.unselectJobApplication()
      this.unselectCandidate()
      this.setManualDataIsLoading(false)
    }
  },
  methods: {
    ...mapMutations('page', ['setManualDataIsLoading']),
    ...mapActions('jobApplications', [
      'getJobApplication',
      'unselectJobApplication'
    ]),
    ...mapActions('candidates', ['unselectCandidate', 'getCandidate'])
  }
}
</script>
