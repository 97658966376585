<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('job_applications.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-row v-if="!dataIsLoading">
      <v-col>
        <v-form ref="form">
          <DatePicker
            v-model="form.availability"
            min="1950-01-01"
            :label="$t('job_applications.availability')"
            :required=true
            @update="data => (form.availability = data)"
          />
          <v-text-field
            v-model="form.rate"
            :label="$t('job_applications.rate')"
            :rules="[rules.numerical]"
            suffix="%"
          />
          <v-row class="mt-3">
            <v-btn class="primary--text text--darken-2 mb-5 ml-3" @click="addWorkingPeriod">
              {{ this.$t('job_applications.title') }}
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-row>
          <v-row
            v-for="(period, index) in form.workingPeriods"
            :key="period.id"
          >
            <v-col cols="3">
              <v-select
                v-model="period.weekday"
                :items="weekdays"
                item-text="name"
                item-value="code"
                :label="$t('job_applications.working_period.weekday')"
                :rules="[rules.required]"
                class="required"
              />
            </v-col>
            <v-col cols="4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="period.from_hour"
                    :label="$t('job_applications.working_period.from')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    class="required"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="period.from_hour"
                  :allowed-minutes="allowedStep"
                  format="24hr"
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="period.to_hour"
                    :label="$t('job_applications.working_period.to')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    class="required"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="period.to_hour"
                  :allowed-minutes="allowedStep"
                  format="24hr"
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-btn class="mt-4" icon @click="deletePeriod(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="form.irregularlyHours"
            :label="$t('job_applications.irregularly_hours')"
          />
          <v-text-field
            v-model="form.travelTime"
            :label="$t('job_applications.travel_time')"
            :rules="[rules.required, rules.numerical]"
            class="required"
            :suffix="this.$t('job_applications.travel_time_suffix')"
          />
          <v-checkbox
            v-model="form.drivingLicense"
            :label="$t('job_applications.driving_license')"
          />
          <v-checkbox
            v-model="form.hasVehicle"
            :label="$t('global.have_vehicle')"
          />
          <v-checkbox
            v-model="form.remotePossible"
            :label="$t('job_applications.remote_possible')"
          />
          <v-text-field
            v-model="form.healthSpecialNeeds"
            :label="$t('job_applications.health_special_needs')"
          />
          <v-text-field
            v-model="form.salaryClaim"
            :label="$t('job_applications.salary_claim')"
            :rules="[rules.required, rules.numerical]"
            class="required"
            :suffix="this.$t('job_applications.salary_claim_suffix')"
          />
          <v-row justify="center">
            <v-btn
              v-if="jobApplication"
              color="primary"
              @click="showExplicitActivities"
            >
              {{ $t('activities.explicit') }}
            </v-btn>
            <v-btn class="ml-1" color="primary" @click="submitJobApplication">
              {{ $t('global.save') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { weekdays } from '@/helpers/weekdays'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import DatePicker from '@/components/fields/DatePicker.vue'
import RouterMixin from '@/mixins/routerMixin'
import store from "@/store";

export default {
  components: { ComponentTitleWithDataLoader, DatePicker },
  mixins: [RouterMixin],
  props: {
    jobApplication: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      form: {
        availability: null,
        rate: null,
        workingPeriods: [],
        irregularlyHours: false,
        travelTime: null,
        drivingLicense: false,
        hasVehicle: false,
        remotePossible: false,
        healthSpecialNeeds: null,
        salaryClaim: null,
      },
      rules: {
        required: v => !!v,
        numerical: value => {
          if (!value) return true
          const pattern = /^\d*$/
          return (
            (value && pattern.test(value)) ||
            this.$t('job_applications.validation.errors.numerical')
          )
        }
      }
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('candidates', ['candidate']),
    weekdays() {
      return weekdays
    }
  },
  watch: {
    jobApplication: {
      handler(jobApplication) {
        if (jobApplication) {
          this.form = JSON.parse(JSON.stringify(jobApplication))
        }
      }
    }
  },
  methods: {
    allowedStep: m => m % 15 === 0,
    submitJobApplication() {
      if (! this.candidate?.id) {
        // this.candidate from vuex state might be null (i.e. creating new application)
        store.dispatch(
          'notifications/showWarning',
          this.$t('job_applications.validation.errors.candidate')
        )
      }

      if (this.$refs.form.validate() && this.candidate?.id) {

        /* TODO SZC-25 :
            - Working field is required by the API,
            - Add field to choose working_field,
            - Remove this workaround =>
        */
        this.form.workingFieldId = 1

        if (this.form.id) {
          this.$store
            .dispatch('jobApplications/updateJobApplication', {
              ...this.form
            })
            .then(() => {
              this.$emit('show-alert')
            })
        } else {
          this.form.candidate = this.candidate
          this.$store
            .dispatch(
              'jobApplications/createJobApplication',
              {
                ...this.form
              }
            )
            .then(() => {
              this.$emit('show-alert')
              this.$router.replace(
                `/jobApplications/${this.jobApplication.id}/details`
              )
            })
        }
      }
    },
    addWorkingPeriod() {
      this.form.workingPeriods.push({
        id: null,
        from_hour: null,
        to_hour: null,
        weekday: null
      })
    },
    deletePeriod(index) {
      this.form.workingPeriods.splice(index, 1)
    },
    showExplicitActivities() {
      this.routerPush(`/jobApplications/${this.jobApplication.id}/activities`)
    }
  }
}
</script>
