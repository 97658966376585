<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="`${this.$t('candidate.personal_data')}`"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-if="!dataIsLoading">
      <v-form ref="form">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.last_name"
              :label="$t('candidate.last_name')"
              :rules="[rules.required]"
              class="required"
              data-cy="last_name"
              :placeholder="$t('candidate.last_name_hint')"
              persistent-placeholder
              :hint="$t('candidate.last_name_hint')"
            />
            <v-text-field
              v-model="form.first_name"
              :label="$t('candidate.first_name')"
              :rules="[rules.required]"
              class="required"
              data-cy="first_name"
            />
            <v-text-field
              v-model="form.address.address1"
              :label="$t('global.address.address1')"
              :rules="[rules.required]"
              class="required"
              data-cy="address.address_1"
            />
            <v-text-field
              v-model="form.address.address2"
              :label="$t('global.address.address2')"
              data-cy="address.address_2"
            />
            <v-text-field
              v-model="form.address.zipCode"
              :label="$t('candidate.zip_code')"
              :rules="[rules.required]"
              class="required"
              data-cy="address.zip_code"
            />
            <v-text-field
              v-model="form.address.city"
              :label="$t('candidate.city')"
              :rules="[rules.required]"
              class="required"
              data-cy="address.city"
            />
            <DatePicker
              v-model="form.birthdate"
              :label="$t('candidate.birthdate')"
              min="1930-01-01"
              :required=true
              :cytext="'birthdate'"
              :cydate="'birthdate_picker'"
              @update="data => (form.birthdate = data)"
            />
            <v-text-field
              v-model="form.mobile_phone_number"
              :label="$t('candidate.mobile_phone_number')"
              :placeholder="this.$t('global.placeholders.phone')"
              validate-on-blur
              :hint="this.$t('global.validation.hints.phone')"
              :rules="[rules.phone]"
              data-cy="mobile_phone_number"
            />
            <v-text-field
              v-model="form.line_phone_number"
              :label="$t('candidate.line_phone_number')"
              :rules="[rules.phone]"
              :placeholder="this.$t('global.placeholders.phone')"
              data-cy="line_phone_number"
              :hint="this.$t('global.validation.hints.phone')"
              validate-on-blur
            />
            <v-text-field
              v-model="form.email"
              :label="$t('candidate.email')"
              :rules="[rules.email]"
              data-cy="email"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.nationality"
              :items="countries"
              item-text="name"
              item-value="code"
              :label="$t('candidate.nationality')"
              :rules="[rules.required]"
              class="required"
              data-cy="nationality"
            />
            <v-select
              v-show="!isSwiss"
              v-model="form.residence_permit"
              :items="permits"
              :label="$t('candidate.residence_permit')"
              :rules="[rules.requiredIfNotSwiss]"
              class="required"
              data-cy="residence_permit"
            />
            <div v-show="!isSwiss">
              <DatePicker
                v-model="form.residence_permit_expiration_date"
                :label="$t('candidate.residence_permit_expiration_date')"
                :rules="[rules.requiredIfNotSwiss]"
                min="1950-01-01"
                :cytext="'residence_permit_expiration_date'"
                :cydate="'residence_permit_expiration_date_picker'"
                @update="data => (form.residence_permit_expiration_date = data)"
              />
            </div>
            <v-checkbox
              v-model="form.refugee"
              :label="$t('candidate.refugee')"
              data-cy="refugee"
            />
            <DatePicker
              v-model="form.unemployment_date"
              :label="$t('candidate.unemployment_date')"
              min="1950-01-01"
              :cytext="'unemployment_date'"
              :cydate="'unemployment_date_picker'"
              @update="data => (form.unemployment_date = data)"
            />
            <v-checkbox
              v-model="form.orp_inscription"
              :label="$t('candidate.orp_inscription')"
              data-cy="orp_inscription"
            />
            <v-checkbox
              v-show="form.orp_inscription === true"
              v-model="form.insertion_measure"
              :label="$t('candidate.insertion_measure')"
              data-cy="insertion_measure"
            />
            <v-checkbox
              v-model="form.it_skills"
              :label="$t('candidate.it_skills')"
              :hint="$t('candidate.it_skills_hint')"
              persistent-hint
              data-cy="it_skills"
            />
            <v-checkbox
              v-model="form.pursuits"
              :label="$t('candidate.pursuits')"
              data-cy="pursuits"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn color="primary" data-cy="submit" @click="submitCandidate">
            {{ $t('global.save') }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import DatePicker from '@/components/fields/DatePicker.vue'

const isoCountries = require('i18n-iso-countries')

export default {
  name: 'PersonalData',
  components: { ComponentTitleWithDataLoader, DatePicker },
  props: {
    candidate: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      rules: {
        // TODO Centralize rules (SZC-150)
        required: v => !!v,
        phone: value => {
          if (!value) return true
          const pattern = /^\+?\d*$/
          return (
            (value && value.length === 12 && pattern.test(value))
            || this.$t('global.validation.errors.phone')
          )
        },
        email: value => {
          if (!value) return true
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (
            pattern.test(value) || this.$t('global.validation.errors.email')
          )
        },
        requiredIfNotSwiss: value => !(!this.isSwiss && value === null)
      },
      permits: ['L', 'B', 'C', 'G', 'F', 'S'],
      activeBirthdatePicker: null,
      form: {
        last_name: null,
        first_name: null,
        address: {
          address1: null,
          address2: null,
          zipCode: null,
          city: null
        },
        birthdate: null,
        mobile_phone_number: null,
        line_phone_number: null,
        email: null,
        nationality: null,
        residence_permit: null,
        residence_permit_expiration_date: null,
        refugee: false,
        unemployment_date: null,
        orp_inscription: false,
        insertion_measure: false,
        it_skills: false,
        pursuits: false
      }
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),

    countries() {
      // eslint-disable-next-line global-require
      isoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
      const names = Object.entries(
        isoCountries.getNames('fr', { select: 'official' })
      )

      const startCodes = ['CH', 'FR', 'DE', 'PT', 'ES']
      const startEntries = []
      const defaultEntries = []
      names.forEach((country) => {
        const startCountryIndex = startCodes.indexOf(country[0])
        const entry = {code: country[0], name: country[1]}
        if (startCountryIndex !== -1) {
          startEntries.splice(startCountryIndex, 0, entry)
        } else {
          defaultEntries.push(entry)
        }
      })
      return startEntries.concat(startEntries, defaultEntries)
    },
    isSwiss() {
      return this.form.nationality === null || this.form.nationality === 'CH'
    }
  },
  watch: {
    candidate: {
      handler(candidate) {
        if (candidate) {
          this.form = JSON.parse(JSON.stringify(candidate))
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('candidates', ['getCandidates', 'updateCandidate', 'createCandidate']),

    submitCandidate() {
      this.adjustValuesForValidation()
      if (this.$refs.form.validate()) {
        if (this.form.id) {
          this.updateCandidate(this.form)
            .then(() => {
              this.$emit('saved', this.form.id)
            })
        } else {
          this.createCandidate(this.form).then(createdCandidate => {
            this.$emit('saved', createdCandidate.id)
          })
        }
      }
    },
    adjustValuesForValidation() {
      if (this.form.orp_inscription === false) {
        this.form.insertion_measure = false
      }

      if (this.isSwiss) {
        this.form.residence_permit = null
        this.form.residence_permit_expiration_date = null
      }
    }
  }
}
</script>
